<template>
  <q-page class="q-pa-sm">
    <div class="row">
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.title" :rules="[val => $validators.required(val)]"
                   class="full-width"
                   clearable
                   dense
                   label="Заголовок"
                   outlined/>
        </q-item>
      </div>

      <div class="col-12">
        <q-item>
          <q-input v-model="entity.redirect_url"
                   :rules="[val => $validators.isValidUrl(val) || !val  || 'Некорректная ссылка']"
                   class="full-width"
                   clearable
                   dense
                   label="Ссылка на новость"
                   outlined/>
        </q-item>
      </div>

      <div v-if="entityId" class="col-12">
        <q-item>
          <image-uploader v-model="entity.image_url"
                          :uploadMethod="file => new NewsApi().newsControllerUploadImage(file, entityId || 1)"
                          class="full-width"
                          label="Изображение для новости (PNG)"/>
        </q-item>
      </div>

      <div class="col-12">
        <q-item>
          <q-input v-model="entity.content" autogrow class="full-width"
                   clearable
                   dense
                   label="Описание"
                   outlined/>
        </q-item>
      </div>
    </div>

    <div class="q-pa-md">
      <q-btn :label="entityId ? 'Сохранить' : 'Создать'" :loading="loading"
             class="q-mr-md"
             color="primary"
             icon="save"
             rounded
             @click="saveEntity"/>

      <q-btn class="q-mr-md"
             icon="cancel"
             label="Назад"
             rounded
             @click="$router.back()"/>
    </div>
  </q-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { Notify } from 'quasar';
import ImageUploader from '@/components/forms/imageUploader';
import { NewsApi } from '@/api/apis/news-api.ts';

export default defineComponent({
  components: {
    ImageUploader,
  },
  setup() {
    const route = useRoute();
    const entityId = ref(route.params.id);
    const entity = ref({
      title: '',
      content: '',
      redirect_url: '',
      image_url: '',
    });

    if (entityId.value) {
      new NewsApi().newsControllerFindOneArticle(entityId.value)
        .then(({ data }) => {
          entity.value = data;
        })
        .catch(() => entityId.value = 0);
    }

    const loading = ref(false);
    return {
      NewsApi,
      entity,
      entityId,
      loading,

      async saveEntity() {
        loading.value = true;
        try {
          delete entity.value.article_id;

          if (entityId.value) {
            await new NewsApi().newsControllerUpdateArticle({
              title: entity.value.title,
              content: entity.value.content,
              redirect_url: entity.value.redirect_url,
              image_url: entity.value.image_url,
            }, entityId.value);
          } else {
            const { data } = await new NewsApi().newsControllerCreateArticle(entity.value);
            if (data?.article_id) {
              entityId.value = data?.article_id;
            }
          }

          Notify.create({
            type: 'positive',
            message: 'Сохранено',
          });
        } catch (e) {
          console.error(e);
        }

        loading.value = false;
      },
    };
  },
});
</script>

<style>

</style>
